<template>
	<div>
		<v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					ref="button"
					v-on="{ ...on, ...$on, ...$listeners }"
					v-bind="{ ...attrs, ...$attrs }"
					link
					text
					@contextmenu.stop.native.prevent="menu = !menu"
				>
					<v-icon v-if="iconLeft" left>{{ iconLeft }}</v-icon>
					<slot ref="content"></slot>
					<v-icon v-if="iconRight" right>{{ iconRight }}</v-icon>
				</v-btn>
			</template>
			<span>{{ tooltip }}</span>
		</v-tooltip>

		<v-menu
			transition="slide-x-transition"
			:activator="$refs.button"
			v-model="menu"
			offset-y
			offset-x
			nudge-top="10"
			nudge-left="40"
		>
			<v-sheet>
				<v-list dense nav color="transparent">
					<v-list-item-group>
						<v-list-item v-for="(a, index) in actions" :key="index" @click="a.handler">
							<v-list-item-icon>
								<v-icon>{{ a.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<span class="text-nowrap">{{ a.text }}</span>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-sheet>
		</v-menu>
	</div>
</template>

<script>
export default {
	props: {
		tooltip: String,
		iconLeft: String,
		iconRight: String,
	},
	data() {
		return {
			menu: false,
			actions: [
				{ icon: 'mdi-arrow-up', text: 'Abrir', filter: () => !!this.$attrs.to, handler: () => { this.$router.push(this.$attrs.to) } },
				{
					icon: 'mdi-open-in-new', text: 'Abrir en una nueva pestaña', filter: () => !!this.$attrs.to, handler: () => {
						const { href } = this.$router.resolve(this.$attrs.to);
						window.open(href, '_blank')
					}
				},
				{
					icon: 'mdi-clipboard-outline', text: 'Copiar texto', handler: () => {
						try {
							navigator.clipboard.writeText(this.$refs.button.$el.querySelector('.v-btn__content').textContent.trim())
							this.$root.$emit('snack', 'Texto copiado')
						} catch (err) {
							this.$root.$emit('snack', 'Ha ocurrido un error')
						}
						// clipboard.write(  )
					}
				},
			].filter(act => act.filter == null || act.filter() !== false)
		}
	},
	methods: {
		log() {
			console.log("CLICK DERECHO")
		}
	}
}
</script>